module.exports = [{
      plugin: require('/home/runner/work/blog-scratch/blog-scratch/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-143420846-2","head":true,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"coffay.haus"},
    },{
      plugin: require('/home/runner/work/blog-scratch/blog-scratch/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"showCaptions":true},
    },{
      plugin: require('/home/runner/work/blog-scratch/blog-scratch/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
